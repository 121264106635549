import React from "react";

const ComingSoon = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}>Stay tuned!</h1>
        {/* <p style={styles.subText}>Something amazing is on its way! Stay tuned!</p> */}
      </div>
      {/* <div style={styles.loader}></div> */}
    </div>
  );
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "#DDC38B", // Cool-toned gradient with purple and blue
    color: "#652222", // White text for contrast
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif", // Elegant modern font
    overflow: "hidden",
  },
  content: {
    zIndex: 1, // Ensures text is on top of any other element
  },
  heading: {
    fontSize: "4.5rem", // Larger heading
    fontWeight: "700", // Bold heading
    marginBottom: "1.5rem",
    letterSpacing: "3px", // Added letter spacing for effect
    textShadow: "3px 3px 6px rgba(0, 0, 0, 0.4)", // Stronger text shadow for better visibility
  },
  subText: {
    fontSize: "1.8rem", // Increased size for readability
    color: "#652222 ", // Light gray color for the subtext
    fontWeight: "400", // Regular weight for the subtext
    lineHeight: "1.5", // Improved readability
    marginBottom: "3rem", // Increased spacing from the loader
  },
  loader: {
    border: "4px solid transparent",
    borderTop: "4px solid #00bcd4", // Soft teal color for the spinner
    borderRadius: "50%",
    width: "60px", // Larger size for better visibility
    height: "60px", // Circular loader
    animation: "spin 1s ease-in-out infinite", // Smooth spinner animation
    marginTop: "2rem",
  },
  '@keyframes spin': {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export default ComingSoon;